export default [

  {
    url: '/',
    name: 'Instrucciones',
    slug: 'home',
    icon: 'HomeIcon'
  },
  {
    url: '/validador-pdf-signature',
    name: 'Validador PDF',
    slug: 'V-pdf',
    icon: 'ShieldIcon'
  },
  {
    url: '/validador-xml',
    name: 'Validador XML',
    slug: 'V-xml',
    icon: 'ShieldIcon'
  },
  {
    url: null,
    name: 'Avanzado',
    slug: 'avanzado',
    icon: 'MoreHorizontalIcon',
    submenu: [
      {
        url: '/validador-firmas',
        name: 'Validador de firmas',
        slug: 'V-sign',
        icon: 'Edit2Icon'
      },
      {
        url: '/validador-constancia',
        name: 'Validador NOM-151',
        slug: 'V-sign',
        icon: 'FileTextIcon'
      }
    ]
  }
]
